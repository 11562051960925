import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Defining a blog service and messages";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "defining-a-blog-service-and-messages",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#defining-a-blog-service-and-messages",
        "aria-label": "defining a blog service and messages permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defining a blog service and messages`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-create-a-proto-file"
        }}>{`1. Create a proto file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-define-a-blog-post"
        }}>{`2. Define a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-add-service-methods"
        }}>{`3. Add service methods`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-add-request-types"
        }}>{`4. Add request types`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-add-a-response-type"
        }}>{`5. Add a response type`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#6-compile-the-proto-file"
        }}>{`6. Compile the proto file`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-step"
        }}>{`Next step`}</a></li>
    </ul>
    <p>{`Let's begin by defining our gRPC blog service in a proto file.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`No preparation is required for this step. Do check that you've prepared the `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/#prerequisites"
      }}>{`prerequisites`}</a>{`.`}</p>
    <h2 {...{
      "id": "1-create-a-proto-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-create-a-proto-file",
        "aria-label": "1 create a proto file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Create a proto file`}</h2>
    <p>{`Create a file, `}<inlineCode parentName="p">{`blog.proto`}</inlineCode>{` inside the directory, `}<inlineCode parentName="p">{`{project_root}/src/main/proto`}</inlineCode>{`. This tutorial uses `}<a parentName="p" {...{
        "href": "https://developers.google.com/protocol-buffers/docs/proto3"
      }}>{`Protocol Buffers version 3`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf",
        "metastring": "filename=blog.proto",
        "filename": "blog.proto"
      }}>{`syntax = "proto3";

package example.armeria.blog.grpc;
option java_package = "example.armeria.blog.grpc";
option java_multiple_files = true;
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`  See `}<a parentName="p" {...{
          "href": "/tutorials/grpc/blog#sample-service"
        }}>{`Sample service structure`}</a>{` for the overall folder structure.`}</p>
    </Tip>
    <h2 {...{
      "id": "2-define-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-define-a-blog-post",
        "aria-label": "2 define a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Define a blog post`}</h2>
    <p>{`In the proto file, define the `}<inlineCode parentName="p">{`BlogPost`}</inlineCode>{` message type with minimal data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf",
        "metastring": "filename=blog.proto",
        "filename": "blog.proto"
      }}>{`message BlogPost {
  int32 id = 1;
  string title = 2;
  string content = 3;
  int64 createdAt = 4;
  int64 modifiedAt = 5;
}
`}</code></pre>
    <h2 {...{
      "id": "3-add-service-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-add-service-methods",
        "aria-label": "3 add service methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Add service methods`}</h2>
    <p>{`Add service methods to the blog service. We have two methods for retrieving blog posts; one is for retrieving a single post and another for multiple posts.`}</p>
    <Tip mdxType="Tip">
      <p>{`  The APIs are designed based on the `}<a parentName="p" {...{
          "href": "https://google.aip.dev/general"
        }}>{`Google's API design decisions`}</a>{`.`}</p>
    </Tip>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf",
        "metastring": "filename=blog.proto",
        "filename": "blog.proto"
      }}>{`import "google/protobuf/empty.proto";

service BlogService {
  rpc CreateBlogPost (CreateBlogPostRequest) returns (BlogPost) {}
  rpc GetBlogPost (GetBlogPostRequest) returns (BlogPost) {}
  rpc ListBlogPosts (ListBlogPostsRequest) returns (ListBlogPostsResponse) {}
  rpc UpdateBlogPost (UpdateBlogPostRequest) returns (BlogPost) {}
  rpc DeleteBlogPost (DeleteBlogPostRequest) returns (google.protobuf.Empty) {}
}
`}</code></pre>
    <h2 {...{
      "id": "4-add-request-types",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-add-request-types",
        "aria-label": "4 add request types permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Add request types`}</h2>
    <p>{`Add request types for create, retrieve, update, and delete operations.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf",
        "metastring": "filename=blog.proto",
        "filename": "blog.proto"
      }}>{`message CreateBlogPostRequest {
  string title = 1;
  string content = 2;
}

message GetBlogPostRequest {    // For retrieving a single post
  int32 id = 1;
}

message ListBlogPostsRequest {  // For retrieving multiple posts
  bool descending = 1;
}

message UpdateBlogPostRequest {
  int32 id = 1;
  string title = 2;
  string content = 3;
}

message DeleteBlogPostRequest {
  int32 id = 1;
}
`}</code></pre>
    <h2 {...{
      "id": "5-add-a-response-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-add-a-response-type",
        "aria-label": "5 add a response type permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Add a response type`}</h2>
    <p>{`Add a response type to return multiple blog posts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf",
        "metastring": "filename=blog.proto",
        "filename": "blog.proto"
      }}>{`message ListBlogPostsResponse {
    repeated BlogPost blogs = 1;
}
`}</code></pre>
    <h2 {...{
      "id": "6-compile-the-proto-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-compile-the-proto-file",
        "aria-label": "6 compile the proto file permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Compile the proto file`}</h2>
    <p>{`Compile the `}<inlineCode parentName="p">{`blog.proto`}</inlineCode>{` file to generate Java code.
You can refer to the full `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/grpc/build.gradle"
      }}>{`build.gradle`}</a>{` file for generating code with `}<a parentName="p" {...{
        "href": "https://github.com/google/protobuf-gradle-plugin"
      }}>{`protobuf-gradle-plugin`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./gradlew generateProto
`}</code></pre>
    <p>{`You'll see the generated Java code in the `}<inlineCode parentName="p">{`{project_root}/build/generated/source/proto/main`}</inlineCode>{` folder.`}</p>
    <h2 {...{
      "id": "next-step",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-step",
        "aria-label": "next step permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next step`}</h2>
    <p>{`In this step, we've defined a proto file for our service and generated Java code. Next, we'll `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/run-service"
      }}>{`run a service`}</a>{` and test the connection.`}</p>
    <TutorialSteps current={1} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      